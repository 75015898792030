import { useState } from "react";
import { FaStar } from "react-icons/fa";
import './styles.css';

export default function StarRating({ noOfStars = 5 }) {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);

  function handleClick(getCurrentIndex) {
    setRating(getCurrentIndex);
  }

  function handleMouseEnter(getCurrentIndex) {
    setHover(getCurrentIndex);
  }

  function handleMouseExit(getCurrentIndex) {
    setHover(rating)
  }

  return (
    <div className="star_rating">
      {[...Array(noOfStars)].map((_, index) => {
        index++;

        return (
          <FaStar
            key={index}
            className={index <= (hover || rating)? 'active' : 'inactive'}
            onClick={() => handleClick(index)}
            onMouseMove={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseExit(index)}
            size={40}
          />
        );
      })}
    </div>
  );
}
